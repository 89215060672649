/*
 * @Author: 朱晓真
 * @Date: 2023-04-19 11:31:24
 * @LastEditors: pidejun pidejun@yuanbaobaoxian.com
 * @LastEditTime: 2025-01-23 17:48:17
 * @Description: 背景音频管理者
 */

import { preLoadMedia as preLoadMediaUtil } from 'src/common/utils/resourceLoadUtil';
import type BGAudioCom from 'component/BGAudioCom';

interface audioOtherParams {
  // 该声音是否能重复播放
  canRepeat?: boolean;
  /**
   * 播放动作来源
   * 1 业务代码调用
   * 2 touchstart方法
   * 3 关闭阻塞
   * 4 新的关闭阻塞
   */
  actionSource?: number;

  /**
   * 音频是否在音频锁期间播放 页面锁期间需要播放的声音使用此属性，不能使用forcePlay
   *
   */
  needPlayInPageBlocking?: boolean;

}

interface AudioParams {
  audioOtherParams?: audioOtherParams;
}

class AudioBGManager {
  // eslint-disable-next-line no-use-before-define
  private static instance: AudioBGManager;

  private preventDefaultPlayIdList = [];

  private constructor() {
    document.addEventListener('touchstart', this.handleTouchstart);
  }

  // 获取单例实例
  static getInstance(): AudioBGManager {
    if (AudioBGManager.instance) return AudioBGManager.instance;
    AudioBGManager.instance = new AudioBGManager();
    return AudioBGManager.instance;
  }

  private isHandPage = false;

  public get getIsHandPage() {
    return this.isHandPage;
  }

  /**
   * 当前音频组件的引用
   */
  private audioRef: BGAudioCom;

  /**
   * 当前要播放的音频
   */
  private curAudioSrc: string;

  /**
   * 当前要播放的音频参数
   */
  private curAudioParams: AudioParams;

  /**
   * 页面被锁住的音频
   */
  private blockPageAudioSrc: string;

  /**
   * 播放功阻塞
   */
  private isBlock = false;

  public get getIsBlock() {
    return this.isBlock;
  }

  /**
   * blockModalAudioList 弹窗锁，弹窗出现会添加一个 key，关闭的时候删除对应的 key，blockModalAudioList.size > 0 时，阻塞音频的播放
   */
  private blockModalAudioList = new Set([]);

  /**
   * 已经播放过的音频路径
   */
  audioPlayedSrcArr = [];

  pageFlagToAudioFieldNameArrMap = new Map([
    ['pzLoginPage', ['loginBGAudioSrc', 'phoneEmptyAudioSrc', 'phoneErrorAudioSrc', 'smsCodeEmptyAudioSrc', 'smsCodeErrorAudioSrc', 'pleaseFillSmsCodeAudioSrc', 'backgroundAudio']],
    [
      'pzLandingPage',
      [
        'phoneEmptyAudioSrc',
        'phoneErrorAudioSrc',
        'smsCodeEmptyAudioSrc',
        'smsCodeErrorAudioSrc',
        'certNoEmptyAudioSrc',
        'certNoErrorAudioSrc',
        'nameEmptyAudioSrc',
        'nameErrorAudioSrc',
        'nameCertNoMatchErrorAudioSrc',
        'page2BGAudioSrc',
        'createOrderPerModalBGAudioSrc',
        'obtainingSecurityPlanAudioSrc',
        'petNameErrAudioSrc',
        'page1BgAudio',
        'page2BgAudio',
        'formLeave',
      ],
    ],
    ['batchUpgrade', ['upgradeBGAudioSrc']],
    ['resultBatchLoad2', ['improveSecurityAudioSrc', 'backgroundAudio']],
    ['giftResultPage', [
      'zdyjpSound',
      'detailNameEmpty',
      'detailCertNoPage',
      'detailNameError',
      'detailCertNoError',
      'detailCertNoMatchErrorAudioSrc',
      'zxtcSound',
      'clickButtonToImproveSecurityAudioSrc',
    ]],
  ]);

  private handleTouchstart = (e) => {
    this.isHandPage = true;
    const currentId = e.target?.id;
    for (const id of this.preventDefaultPlayIdList) {
      if (currentId === id) return;
    }
    // 如果被点击的元素配置了 data-touch-play-audio="0" 那么
    const touchPlayAudio = (e.target as HTMLElement)?.dataset?.touchPlayAudio;
    if (touchPlayAudio === '0') {
      return;
    }
    this.playBGAudio(this.curAudioSrc, undefined, undefined, undefined, {
      needPlayInPageBlocking: this.curAudioParams?.audioOtherParams?.needPlayInPageBlocking,
      actionSource: 2,
    });
  };

  private onStatic = (eventName: string, params?) => {
    if (!eventName) return;
    if (this.audioRef) {
      this.audioRef.onStatic(eventName, params);
    }
  };

  /**
   * 阻塞页面音频播放 页面背景音频锁使用这个
   */
  blockPageAudio() {
    this.onStatic('h5_fe_audio_add_block');
    this.isBlock = true;
    this.pauseBGAudio();
  }

  /**
   * 解锁页面音频播放
   * isPlayBgAudio 是否播放被锁住的音频
   */
  unblockPageAudio(isPlayBgAudio = true) {
    this.onStatic('h5_fe_audio_remove_block');
    this.isBlock = false;
    if (isPlayBgAudio) {
      this.playBGAudio(this.blockPageAudioSrc, undefined, undefined, undefined, {
        actionSource: 4,
      });
    }
    this.blockPageAudioSrc = '';
  }

  /**
   * 阻塞背景音频播放  背景音频锁不要再使用blockBGAudio和closeBGAudioBlock 使用 blockPageAudio和unblockPageAudio
   * isBlock：全局锁，主要在页面初始化的时候使用
   * blockModalAudioList：弹窗锁，弹窗显示加锁，弹窗关闭解锁
   * key: 如果有 key，表示是出现了一系列的弹窗，需要阻断音频的播放，弹窗关闭之后不再播放任何音频
   */
  blockBGAudio(key?: string) {
    this.onStatic('h5_fe_audio_add_block', { blockSrc: key });
    if (key) {
      if (this.blockModalAudioList.has(key)) {
        console.error('该 ab 测弹窗已经被加锁，不能播放音频', key);
        return;
      }
      this.blockModalAudioList.add(key);
    } else {
      this.isBlock = true;
    }
    this.pauseBGAudio();
  }

  /**
   * 关闭阻塞-重新播放
   * isBlock：全局锁，主要在页面初始化的时候使用
   * blockModalAudioList：弹窗锁，弹窗显示加锁，弹窗关闭解锁
   * key: 如果有 key，表示是出现了一系列的弹窗，需要阻断音频的播放，弹窗关闭之后不再播放任何音频
   */
  closeBGAudioBlock = (key?: string) => {
    this.onStatic('h5_fe_audio_remove_block', { blockSrc: key });
    if (key) {
      if (!this.blockModalAudioList.has(key)) {
        console.error('该 ab 测弹窗已经被解锁过', key);
        return;
      }
      this.blockModalAudioList.delete(key);
      return;
    }
    this.isBlock = false;
    this.playBGAudio(this.curAudioSrc, undefined, undefined, undefined, {
      actionSource: 3,
    });
  };

  /**
   * 处理页面背景音频锁相关逻辑
   * @param src 传递则播放新的，不传递就播放记录的要播放的src
   * @param otherParams 其他参数
   */
  handlePageBlockAudio = (src?: string, otherParams?: audioOtherParams) => {
    if (otherParams?.needPlayInPageBlocking) {
      // 当前被阻止的音频记录参数  交互时播放的时候也要附带
      this.curAudioParams = {
        audioOtherParams: otherParams,
      };
    } else {
      // 阻塞的音频 仅用于释放页面锁时播放出来
      this.blockPageAudioSrc = src || this.blockPageAudioSrc;
      this.curAudioParams = {};
    }
  };

  /**
   * 播放音频
   * @param src 传递则播放新的，不传递就播放记录的要播放的src
   * @param forcePLay 强制播放音频，不受阻塞和已经播放过的限制
   * @param preventDefaultPlayIdList 设置禁止触发播放的dom id列表
   * @param trackParams 埋点参数
   * @param otherParams 其他参数
   * @returns boolean 是否调用了音频播放
   */
  playBGAudio = (
    src?: string,
    forcePLay?: boolean,
    preventDefaultPlayIdList?: Array<string>,
    trackParams?: Record<string, unknown>,
    otherParams?: audioOtherParams,
  ): boolean => {
    // 阻塞音频播放后的那次播放需去掉this.curAudioSrc的副作用 否则如果未播放，touch监听会再次播
    if (otherParams?.actionSource === 4) {
      if (!src) {
        this.curAudioSrc = src;
        return false;
      }
    }

    this.curAudioSrc = src || this.curAudioSrc;

    if (this.isBlock) {
      this.handlePageBlockAudio(src, otherParams);
    }

    const actionSource = otherParams?.actionSource || 1;

    if (preventDefaultPlayIdList) this.preventDefaultPlayIdList = preventDefaultPlayIdList;
    if (forcePLay && this.curAudioSrc && this.audioRef) {
      this.audioRef.play(this.curAudioSrc, true, trackParams, {
        playAction: 2,
        actionSource,
      });
      return true;
    }
    // 需要检查一下全局锁和弹窗锁， 被锁住时除了特定的音频，其他不能播放
    if (this.blockModalAudioList.size > 0 || (this.isBlock && !otherParams?.needPlayInPageBlocking)) {
      if (actionSource !== 2) {
        // 不是touch触发的播放
        this.onStatic('h5_fe_audio_prevent', { preventType: 1, preventAudio: this.curAudioSrc });
      }
      return false;
    }
    if (!this.curAudioSrc || !this.audioRef) return false;

    // 默认同一个声音只会播放一次，如果配置项中canRepeat为ture，则同一个声音能够重复播放
    if (this.audioPlayedSrcArr.includes(this.curAudioSrc.trim()) && !otherParams?.canRepeat) {
      if (actionSource !== 2) {
        // 不是touch触发的播放
        this.onStatic('h5_fe_audio_prevent', { preventType: 2, preventAudio: this.curAudioSrc });
      }
      return false;
    }

    this.audioRef.play(this.curAudioSrc, false, trackParams, {
      playAction: forcePLay ? 2 : 1,
      actionSource,
    });
    return true;
  };

  /**
   * 强制重新播放
   * @param src 传递则播放新的，不传递就播放记录的要播放的src
   * @param trackParams 埋点额外参数
   * @tips 第一次是简单播放，第二次会强制从头开始播放
   */
  forceRePlay = ({ src, trackParams }) => {
    this.curAudioSrc = src || this.curAudioSrc;
    if (this.curAudioSrc && this.audioRef) {
      this.audioRef.play(this.curAudioSrc, true, trackParams, {
        playAction: 2,
        actionSource: 1,
      });
    }
  };

  /**
   * 循环播放音频
   */
  loopPlay = ({ src }) => {
    this.curAudioSrc = src || this.curAudioSrc;
    if (this.curAudioSrc && this.audioRef) {
      this.audioRef.loopPlay(this.curAudioSrc);
    }
  };

  /**
   * 暂停播放当前音频
   * @param isResetStart 是否重置播放进度
   * @param actionSource 1 业务代码调用 2 block触发
   */
  // eslint-disable-next-line default-param-last
  pauseBGAudio = (isResetStart = false, actionSource = 1) => {
    this.audioRef?.pause?.();
    if (isResetStart) {
      this.audioRef?.setCurrentTime();
    }
    this.onStatic('h5_fe_audio_do_pause', {
      pauseSource: actionSource,
    });
  };

  /**
   * 停止播放音频
   * @warning 因方法会清除播放器当前的音频链接，
   * 所以本方法仅限有明确暂停音频链接的场景使用
   */
  stopAudio = (audioSrc: string) => {
    if (!audioSrc) return;
    const isCanStop = this.audioRef?.stopAudio?.(audioSrc);
    if (isCanStop) {
      // 可以清除音频
      this.curAudioSrc = ''; // 重置当前音频
    }
  };

  /**
   * 重置音频播放
   */
  resetAudioPlay = () => {
    this.curAudioSrc = ''; // 重置当前音频
    this.audioPlayedSrcArr = []; // 重置历史播放记录
  };

  /**
   * 挂载ref媒体组件对象
   * @param audioRef
   */
  mountAudioRef = (audioRef?: any) => {
    this.audioRef = audioRef;
    if (!audioRef) {
      this.resetAudioPlay();
      return; // 卸载之前的audio组件的时候，直接返回
    }
    this.audioRef.playInstance = AudioBGManager.getInstance();
    this.audioPlayedSrcArr = [];
  };

  handleOnPlay = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    const audioEle = event.target as HTMLAudioElement;
    this.audioPlayedSrcArr.push(audioEle?.src || this.curAudioSrc);
  };

  /**
   * 预加载媒体
   * @param pzAudioAbtest
   * @param pageFlag 页面标志
   */
  preLoadMedia = (audioAbtest: any, pageFlag: 'pzLoginPage' | 'pzLandingPage' | 'batchUpgrade' | 'resultBatchLoad2' | 'giftResultPage') => {
    const audioFieldArr = this.pageFlagToAudioFieldNameArrMap.get(pageFlag);
    audioFieldArr?.forEach((fieldName) => {
      if (audioAbtest[fieldName]) preLoadMediaUtil(audioAbtest[fieldName]);
    });
  };
}

export default AudioBGManager;
