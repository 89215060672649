/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2022-10-21 09:38:39
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-10-16 14:31:33
 * @Description: 理赔相关
 */
import serviceRoute from './service/route';

export default [{
  path: '/claim',
  component: () => import('./index'),
  routes: [
    {
      path: '/claim/sign', // 理赔签字中间跳转页
      component: () => import('./sign'),
    },
    {
      path: '/claim/videoStory', // 理赔视频页
      component: () => import('./videoStory'),
    },
    {
      path: '/claim/detail', // 理赔详情页
      component: () => import('./detail'),
    },
    {
      path: '/claim/material', // 理赔所需资料页
      title: '理赔所需资料',
      component: () => import('./material'),
    },
    {
      path: '/claim/process', // 理赔申请流程
      title: '理赔申请流程',
      component: () => import('./process'),
    },
    {
      path: '/claim/insureScope', // 理赔保障范围
      title: '保障范围查询',
      component: () => import('./insureScope'),
    },
    {
      path: '/claim/materialObtain', // 理赔资料获取页
      title: '就诊资料收集指引',
      component: () => import('./materialObtain'),
    },
    ...serviceRoute,
  ],
}, {
  path: '/allowance',
  component: () => import('./allowance'),
  title: '健康测评',
  routes: [
    {
      path: '/allowance/imagecollect',
      title: '我的认证',
      component: () => import('./allowance/ImagesCollect'),
    },
  ],
}];
