/*
 * @Author: wangchen wangchen@yuanbaobaoxian.com
 * @Date: 2023-09-07 13:59:31
 * @LastEditors: wangchen wangchen@yuanbaobaoxian.com
 * @LastEditTime: 2024-02-28 19:12:21
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/about/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  path: '/about',
  component: () => import('./index'),
  routes: [
    {
      path: '/about/menulist',
      title: '公开信息披露',
      component: () => import('./MenuList'),
    },
    {
      path: '/about/companysummary',
      title: '公司概况',
      component: () => import('./CompanySummary'),
    },
    {
      path: '/about/productreveal',
      title: '保险公司信息披露',
      component: () => import('./Partner/ProductReveal'),
    },
    {
      path: '/about/companyreveal',
      title: '合作保险公司披露',
      component: () => import('./Partner/CompanyReveal'),
    },
    {
      path: '/about/intermediaryreveal',
      title: '合作保险中介披露',
      component: () => import('./Partner/IntermediaryReveal'),
    },
    {
      path: '/about/noticestatement',
      title: '公告声明',
      component: () => import('./NoticeStatement'),
      routes: [
        {
          path: '/about/noticestatement/content',
          title: '公告声明',
          component: () => import('./NoticeStatement/NoticeContent'),
        },
      ],
    },
    {
      path: '/about/personalinfopolicy',
      title: '个人信息保护政策',
      component: () => import('./PersonalInfoPolicy/index.js'),
    },
    {
      path: '/about/publicityandeducation',
      title: '宣传教育专栏',
      component: () => import('./PublicityAndEducation/index.js'),
      routes: [
        {
          path: '/about/publicityandeducation/article',
          component: () => import('./PublicityAndEducation/ArticleDetail'),
        },
      ],
    },
    {
      path: '/about/serviceandcomplaint',
      title: '客户服务与投诉',
      component: () => import('./ServiceAndComplaint/index.js'),
    },
    {
      path: '/about/networkerr',
      title: '网络异常',
      component: () => import('./NetworkErrorPage/index.js'),
    },
  ],
};
