import Fetch from 'fetch';
import { Modal } from 'antd-mobile-v2';
import { Toast } from '@yb/fe-component';
import { transferSearchObj2Str, getSource, addUrlParams, getUrlParam } from 'ybcommon/ybutils/common/common';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import { getHost } from 'ybcommon/ybutils/common/company';
import { jumpToDetail, getInsuranceType } from 'utils/common/common';
import { isWechat, isIOS, isBaidu } from 'common/utils/device';
import { crossRegion } from 'common/utils/crossUtil';
import stores from 'store';
import UIStore from 'src/sys/product/UIStore';
import eventBus from 'ybcommon/eventBus';

// 根据当前路由获取跳转聚合页所需的fromPage参数
const _getFromPage = (history) => {
  const { location } = history || window || {};
  const { pathname } = location || {};
  // 默认others 代表除落地页、待支付页之外的页面
  let fromPage = 'others';
  const cargoReg = /\/cargo|\/sxcx|\/resultNew\/detail|\/resultNew\/cxdetail/g;
  if (pathname === '/order/unpaid') {
    // 待支付页去支付
    fromPage = 'unpaid';
  } else if (cargoReg.test(pathname)) {
    // 落地页下单（包含长/短险落地页、结果页复购）
    fromPage = 'cargo';
  }
  return fromPage;
};

/**
 * 微信支付，目前主要用于自主支付，后续如有复用可进行优化修改
 * @param {*} params 自主参数，目前后台直接返回所有所需参数，无需进一步处理
 * @param {*} callback 结果回调
 */
export function dowepay(params, callback) {
  const onBridgeReady = () => {
    if (!__DEV__) {
      console.log('==onBridgeReady===');
      // eslint-disable-next-line no-undef
      if (WeixinJSBridge && params) {
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke('getBrandWCPayRequest', { ...params, package: params.packageValue }, (res) => {
          if (!callback || typeof callback !== 'function') return;
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            callback({ status: 1 });
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            callback({ status: 2, errMsg: '取消支付' });
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            callback({ status: 3, errMsg: '支付失败' });
          } else {
            callback({ status: 4, errMsg: '未知异常' });
          }
        });
      }
    }
  };

  if (isWechat()) {
    if (__DEV__) {
      onBridgeReady();
    } else if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
    } else {
      onBridgeReady();
    }
  }
}

/**
 * @name: 跳转收银台页面
 * @param {*} url 跳转的页面链接
 * @return {*}
 */
export function goCashierPage(url) {
  crossRegion(url, false, true);
}

/**
 * 跳转到收银台
 * @param {*} history history
 * @param {*} params 参数，
 * payUrlIsJson：0:url；1:json串；2:授权url
 * payUrl：链接或者json串，直接取后台返回即可
 * payPage：跳转页面，1:结果页；2:主动支付结果页，3、只签约不做操作，自己返回到原页面
 * payChannel：支付渠道，1:微信；2:支付宝
 * payment：待支付页支付方式，wxtype:微信 zfbtype:支付宝
 * signDeductFlag：纯签约扣费标识
 * operateType: 跳转聚合收银台 展示银行卡弹窗类型 // 1:添加新卡 2:查看全部
 * bankBindId: 银行卡绑定id
 */
export function goCashier(history, params) {
  const { payUrlIsJson, payUrl, payPage, payChannel, renewalId, orderId, insuranceId, payment, signDeductFlag, operateType, bankBindId, directBankCode } = params;
  if ((payUrl && +payUrlIsJson === 2)) {
    // 需要授权，直接跳转授权页面
    window.location.href = payUrl;
    return;
  }
  if (payChannel === 4) {
    // 聚合收银台
    console.log('****window.__GRAY_FLAG__****', window.__GRAY_FLAG__, typeof window.__GRAY_FLAG__);
    const url = addUrlParams(payUrl, { fromPage: _getFromPage(history), operateType, bankBindId, directBankCode, grflg: window.__GRAY_FLAG__ ? 1 : 0 });
    goCashierPage(url);
    return;
  }
  let query = '';
  const shuntId = window.ybParams?.shuntId;
  if (shuntId) {
    query = `?shuntId=${shuntId}&apayUrlIsJson=${payUrlIsJson}&apayPage=${payPage}`;
  } else {
    query = `?apayUrlIsJson=${payUrlIsJson}&apayPage=${payPage}`;
  }
  if (signDeductFlag) {
    // 纯签约扣费失败标识
    query = `${query}&signDeductFlag=1`;
  }
  if (payChannel) {
    query = `${query}&payChannel=${payChannel}`;
  }
  if (payment) {
    query = `${query}&payment=${payment}`;
  }
  if (orderId && (+payPage === 2 || +payPage === 1)) {
    query = `${query}&orderId=${orderId}`;
  }
  if (insuranceId) {
    query = `${query}&insuranceId=${insuranceId}`;
  }
  if (renewalId) {
    query = `${query}&arenewalId=${renewalId}`;
  }
  if (payUrl) {
    query = `${query}&payUrl=${encodeURIComponent(payUrl)}`;
  }
  history.push(`/pay/cashier${query}`);
}

class PayUtil {
  /**
   * 支付处理
   * @param {*} res 返回信息
   */
  posInsure = (res) => {
    /**
     * 最开始时泰康支付在微信中无法回调统一的页面，所以需要增加返回弹窗，后来泰康修改成支付并签约，但是部分机型无法回调，所以暂时保留
     * 微信外支付时需要返回定位到投保区域，通过isPop字段控制
     */
    const search = window.location.search ? `${window.location.search}&` : '?';
    if (isWechat()) {
      const originurl = `${window.location.pathname}${search}resultsign=1&orderId=${res.orderId || ''}`;
      window.history.replaceState(window.history.state, null, originurl);
    } else {
      const newurl = `${window.location.pathname}${search}isPop=1`;
      if (isIOS() && isBaidu()) {
        window.history.pushState(window.history.state, null, newurl);
      }
      window.history.replaceState(window.history.state, null, newurl);
    }
  };

  // 上报出单动作埋点
  trackCreateOrderEvent = (params = {}) => {
    try {
      const createOrderAction = UIStore.createOrderAction;
      if (
        createOrderAction
        && createOrderAction.href === window.location.href
        && createOrderAction.startTime
      ) {
        trackEvent('create_order_action', {
          actionStartTime: createOrderAction.startTime,
          actionEndTime: Date.now(),
          actionConsumeTime: Date.now() - createOrderAction.startTime,
          ...params,
        });
      }
    } catch (error) {
      window.yb.uplogCatchError(JSON.stringify({ code: 'create_order_action', msg: { message: '出单动作埋点上报异常', stack: '' } }));
    }
  };

  /**
   * 处理小程序内跳转
   * @param {*} history 用于跳转
   * @param {*} source 来源环境
   * @param {*} res 返回数据
   * @param {*} apiType api类型，1：首次支付；2：去支付；3、主动支付；4、签约；5、续保
   * @param {*} params 参数
   */
  _handleMini = (history, source, res, apiType, params) => {
    const { userStore } = stores;
    const { orderId, renewalId, payUrl, renewalUrl, weChatPaySignType = 0, type = 0, companyId, interfaceVersion, operateType, bankBindId, directBankCode } = res;
    const { pageSource, channelId } = params;
    let position = '';
    let channel = '';
    if (channelId && channelId.indexOf('|') > -1) {
      const tempChannel = channelId.split('|');
      position = tempChannel[1];
      channel = tempChannel[0];
    }
    let paramsStr = {};
    switch (source) {
      case 3: // 支付宝小程序且为支付宝TradeNO
        if (+res.type === 3) {
          window.my.postMessage({ payUrl: res.payUrl });
          // 接收来自小程序的消息。
          window.my.onMessage = (e) => {
            if (e && e.isSuccess) {
              history.push(`/resultNew/detail?out_trade_no=${res.orderId}`);
            }
          };
        }
        break;
      case 4: // 微信小程序
        if (+userStore.isWeChatMiniApp === 1 || +userStore.isWeChatMiniApp === 5) {
          // 元保保险商城
          if (payUrl && payUrl.indexOf('https://') === 0) {
            // 收银台页面
            goCashierPage(addUrlParams(payUrl, { fromPage: _getFromPage(history), channel: stores.userStore.channel, shuntId: window.ybParams?.shuntId, operateType, bankBindId, directBankCode, grflg: window.__GRAY_FLAG__ ? 1 : 0 }));
            return;
          }
          if (apiType === 3 && renewalUrl && renewalUrl.indexOf('https://') === 0) {
            // 主动续费
            goCashierPage(addUrlParams(renewalUrl, { fromPage: _getFromPage(history), channel: stores.userStore.channel, shuntId: window.ybParams?.shuntId }));
            return;
          }
          // 元保保险商城
          if (apiType === 1 || apiType === 2) {
            paramsStr = { payUrl: encodeURIComponent(payUrl), orderId, weChatPaySignType, type, companyId, payType: 0 };
          } else if (apiType === 3) {
            paramsStr = { payUrl: encodeURIComponent(renewalUrl), orderId, renewalId, pageSource, weChatPaySignType, type, payType: 1 };
          } else if (apiType === 4) {
            paramsStr = { payUrl: encodeURIComponent(payUrl), orderId, type, payType: 2 };
          } else if (apiType === 5) {
            paramsStr = { payUrl: encodeURIComponent(payUrl), orderId, weChatPaySignType, type, payType: 4 };
          }
          if (position) paramsStr.position = position;
          if (channel) paramsStr.channel = channel;
          window.wx.miniProgram.navigateTo({
            url: `${+userStore.isWeChatMiniApp === 5 ? '/sxmp/pages/functional/pay/index' : '/ybsc/pages/functional/pay/index'}${transferSearchObj2Str(paramsStr)}&interfaceVersion=${interfaceVersion}`,
          });
        }
        break;
      case 5: // 快手小程序
      case 6: // 抖音小程序
        if (apiType === 1 || apiType === 2) {
          paramsStr = { payUrl: encodeURIComponent(payUrl), orderId };
        } else if (apiType === 3) {
          paramsStr = { payUrl: encodeURIComponent(renewalUrl), orderId, renewalId, pageSource };
        }
        if (source === 5) {
          window.ks.navigateTo({
            url: `/pages/pay/index${transferSearchObj2Str(paramsStr)}`,
          });
        } else {
          window.tt.miniProgram.navigateTo({
            url: `/ttyb/pages/pay/index${transferSearchObj2Str(paramsStr)}`,
          });
        }
        break;
      default:
        break;
    }
  };

  /**
   * 处理h5里跳转
   * @param {*} history 用于跳转
   * @param {*} res 返回数据
   * @param {*} sucCB 成功回调
   * @param {*} apiType api类型，1：首次支付；2：去支付；3、主动支付；4、签约；5、续保
   * @param {*} notHandle 执行完sucCB是否继续根据res返回值跳转，0+12直接返回
   */
  handleH5 = (history, res, sucCB, apiType, notHandle = false) => {
    if (sucCB) sucCB(res, 2); // 表示除小程序外回调（小程序内处理后续有需要再添加）
    if (notHandle) return;
    if (+res.type === 4) {
      // 自主支付
      switch (apiType) {
        case 1:
        case 2:
          goCashier(history, { ...res, payPage: 1 });
          break;
        case 3:
          goCashier(history, { ...res, payPage: 2, payUrl: res.renewalUrl });
          break;
        case 4:
          goCashier(history, { ...res, payPage: 3 });
          break;
        case 5:
          goCashier(history, { ...res, payPage: 1 });
          break;
        default:
          break;
      }
    } else if (+res.type === 6 && res.signDeductFlag) {
      // 德华纯签约&&签约成功扣费失败，跳转cashier
      goCashier(history, { ...res, payPage: 1 });
    } else if (+res.type === 2) {
      // form 表单，目前华泰有，众安一年期重疾险有
      history.push({
        pathname: '/pay/middle',
        state: { html: res.formString },
      });
    } else if (res.payUrl) {
      window.location.href = res.payUrl;
    } else if (res.renewalUrl) {
      window.location.href = res.renewalUrl;
    }
  };

  /**
   * 初次创建订单
   * 涉及到的接口：(目前只抽取前两个，TODO:后边四个单独做处理吧)
   *  api/h5/insurance/order/create：已登录态下单
   *  api/h5/insurance/hma/order/create：使用加密userId下单
   *  api/h5/insurance/order/bwgift/create：赠险处下单
   *  api/h5/long/insurance/create：长险下单
   *  api/h5/long/insurance/fude/create：康佑下单
   *  api/h5/long/insurance/fangzheng/pay：方正下单
   */
  /**
   * 初次创建订单
   * @param {*} history 用于跳转
   * @param {*} doBuyUrl 调用接口
   * @param {*} params 请求参数，可以不传source
   * @param {*} sucCB 成功回调，res：返回内容；type：1为统一回到，2为非小程序回调，3为特殊回调
   * @param {*} failCB 失败回调，err：错误内容
   */
  handleCreateOrder = (history, doBuyUrl, params, sucCB, failCB, extraParams) => {
    trackEvent('h5_startBuy');
    const source = getSource();
    Object.assign(params, { source, returnUrl: window.location.href });

    eventBus.emit('before_create_order');

    Fetch.post(doBuyUrl, params)
      .then(async (res) => {
        // 半屏弹框暂不支持小程序
        const { notHandle, canHalfCashier } = extraParams || {};
        const showHalfScreen = res?.halfCashier === 1 && canHalfCashier && source !== 3 && source !== 4 && source !== 5 && source !== 6;
        eventBus.emit('after_create_order_success');
        if (sucCB) await sucCB({ ...res, insuranceId: params.insuranceId, history }, 1, showHalfScreen); // 1：表示统一回调
        Toast.clear();
        if (res.freeSign === 1) return; // 免签，不需要处理后续支付流程
        this.trackCreateOrderEvent({
          source,
          resType: res.type,
          isPayUrl: (res.payUrl || res.renewalUrl) ? 1 : 0,
          notHandle,
        });
        if (showHalfScreen) return; // 半屏弹框
        if (source === 3 || source === 4 || source === 5 || source === 6) {
          this._handleMini(history, source, { ...res, insuranceId: params.insuranceId }, 1, params);
          return;
        }

        this.handleH5(history, { ...res, insuranceId: params.insuranceId }, sucCB, 1, notHandle);
      })
      .catch((err) => {
        eventBus.emit('after_create_order_fail');
        if (failCB) failCB(err);
      });
  };

  /**
   * @description: 获取半屏参数
   * @param {*} type 页面的类型主要用于埋点
   * @param {*} channel
   * @return {*}
   */
  getHalfCashierParams = (res, channel, type) => {
    const { tradeNo, orderId, operateType, insuranceId, payUrl, companyId, history, payCallBack, coupleAbb, combineAbb, composeAbb, halfCashierTemplate, halfSilentAuthorize } = res || {};
    const productAbb = getInsuranceType(insuranceId);
    const fromPage = 'cargo';
    const cashierUrl = addUrlParams(payUrl, { fromPage, operateType });

    let halfCashierInfo = null;
    try {
      halfCashierInfo = JSON.parse(halfCashierTemplate);
    } catch (error) {
      halfCashierInfo = {};
    }
    const cashierParams = {
      halfSilentAuthorize, // 是否需要静默授权,当支付方式为微信,且在微信内支付时
      tradeNo,
      orderId,
      productAbb,
      coupleAbb,
      combineAbb,
      composeAbb,
      channel,
      pageType: 1,
      cashierUrl,
      fromPage,
      halfCashierInfo,
      type,
      companyId, // 刷新支付链接接口使用
      history,
      payCallBack, // 支付回调,用来处理订单过期的额逻辑,目前主要用于待支付页
      appId: getUrlParam('appid', false, cashierUrl?.split('?')?.[1] || ''),
    };
    return cashierParams;
  };

  /**
   * 待支付去支付
   * 涉及到的接口：
   *  api/h5/insurance/order/refreshPayUrl：刷新支付链接
   *  api/h5/insurance/order/queryPayStatusAndRefreshUrl：查询支付状态刷新链接，支付中间页会使用到
   */
  handleRefreshOrder = (history, params, sucCB, failCB, requestUrl) => {
    const source = getSource();
    Object.assign(params, { source });
    if (!params.returnUrl) {
      params.returnUrl = window.location.href;
    }
    const { insuranceId, companyId, orderId, syncid, synctoken, directBankCode } = params;
    Fetch.loadingPost(
      {
        url: requestUrl || '/api/h5/insurance/order/refreshPayUrl',
        params,
      },
      (res) => {
        if (+res.signDeductFlag === 2) {
          // 银行卡支付扣费失败，toast提示引导选择其他支付方式
          Toast.info('扣费失败，您可选择其他方式重新支付');
        }
        const showHalfScreen = res?.halfCashier === 1 && source !== 3 && source !== 4 && source !== 5 && source !== 6;
        if (sucCB) sucCB(res, 1); // 1：表示统一回调
        Toast.clear();
        if (showHalfScreen) return; // 半屏弹框
        if ((source === 3 || source === 4 || source === 5 || source === 6) && res.signDeductFlag !== 1) {
          this._handleMini(history, source, { ...res, insuranceId: params.insuranceId, directBankCode }, 2, params);
          return;
        }
        this.handleH5(history, { ...res, insuranceId: params.insuranceId, payment: params.payment, directBankCode }, sucCB, 2);
      },
      (err) => {
        if (params?.isCompose && typeof failCB === 'function') {
          failCB();
          return;
        }
        if (failCB) failCB(err);
        if (err.code === 9102) {
          Toast.info('您的年龄已发生变化，请重新投保', 3, () => {
            jumpToDetail(insuranceId, history);
          });
        } else if (err.code === 9101) {
          Toast.info('该订单已失效，请重新投保', 3, () => {
            jumpToDetail(insuranceId, history);
          });
        } else if (err.code === 3056 && companyId === 62) { // 德华订单已支付跳转结果页
          Toast.info('订单已支付', 2, () => {
            history.push(addUrlParams('/resultNew/cxdetail', { orderId, syncid, synctoken }));
          });
        } else if (err.msg) {
          // 若3006且是待支付页就不弹alert，只按failCB内的弹Toast 或者 是支付宝半屏也不弹alert; refreshPayMethod 表示从支付宝半屏唤起支付
          if (err.code === 3006 && (window.location.pathname.indexOf('/order/unpaid') > -1 || params?.refreshPayMethod)) return;
          const text = err.msg;
          Modal.alert('', text, [{ text: '知道了' }]);
        }
        if (window.yb && window.yb.uplogCatchError) {
          window.yb.uplogCatchError(JSON.stringify(err));
        }
      },
    );
  };

  /**
   * 主动续费
   * 涉及到的接口：
   *  api/h5/insurance/order/queryActiveRenewalUrl：获取主动支付链接
   *  api/h5/insurance/order/privilege/queryActiveRenewalUrl：获取主动支付链接-不鉴权
   */
  handleActiveRenewal = (history, doARUrl, params, sucCB, failCB) => {
    const source = getSource();
    Object.assign(params, { source, returnUrl: window.location.href });
    Fetch.loadingPost(
      {
        url: doARUrl,
        params,
      },
      (res) => {
        if (sucCB) sucCB(res, 1); // 1：表示统一回调
        Toast.clear();
        if (source === 3 || source === 4 || source === 5 || source === 6) {
          this._handleMini(history, source, res, 3, params);
          return;
        }
        this.handleH5(history, res, sucCB, 3);
      },
      (err) => {
        if (failCB) failCB(err);
      },
    );
  };

  /**
   * 跳转重新签约页面
   * params：跳转页面携带的参数（orderId必传）
   */
  goResignPage = (params) => {
    const { payhost } = getHost();
    const resignPage = addUrlParams(`https://${payhost}/cashier/resign`, params);
    goCashierPage(resignPage);
  };

  /**
   * 签约
   * 涉及到的接口：
   *  api/h5/insurance/order/reSign/getSignUrl：获取重新签约地址地址
   *  api/h5/insurance/order/reSign/privilege/getSignUrl： 获取重新签约地址地址_不鉴权
   */
  handleResign = (history, doResignUrl, params, sucCB, hnCB, failCB) => {
    const source = getSource();
    Object.assign(params, { source });
    Fetch.loadingPost(
      {
        url: doResignUrl,
        params,
      },
      (res) => {
        if (sucCB) sucCB(res, 1); // 1：表示统一回调
        Toast.clear();
        if (source === 3 || source === 4 || source === 5 || source === 6) {
          this._handleMini(history, source, res, 4, params);
          return;
        }
        if (hnCB && res && !res.payUrl && +res.ifSuccess === 1) {
          // 华农再次签约特殊处理
          hnCB(res);
          return;
        }
        this.handleH5(history, res, sucCB, 4);
      },
      (err) => {
        if (failCB) failCB(err);
      },
    );
  };

  /**
   * 续保
   * 涉及到的接口：
   *  api/h5/insurance/order/createRenewal：续保
   */
  handleRenewal = (history, doRenewalUrl, params, sucCB, failCB) => {
    const source = getSource();
    const submitParams = {
      source,
      returnUrl: window.location.href,
      ...(params || {}),
    };
    Fetch.post(doRenewalUrl, submitParams)
      .then((res) => {
        if (sucCB) sucCB(res, 1); // 1：表示统一回调
        Toast.clear();
        if (source === 3 || source === 4 || source === 5 || source === 6) {
          this._handleMini(history, source, res, 5, submitParams);
          return;
        }
        this.handleH5(history, res, sucCB, 5);
      })
      .catch((err) => {
        if (failCB) failCB(err);
      });
  };
}

export default new PayUtil();
