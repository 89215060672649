/* eslint-disable react/require-default-props */
/**
 * @弹窗组件描述 显示时不会使页面滚动到顶部的弹窗
 * @visible 控制弹窗隐藏显示
 * @transparent 让modal背景为透明，默认为白色
 * @popup 属性为false时，animationType默认为'fade'。 属性为true时，animationType默认为'slide-up'可指定为'slide-down'
 * @wrapClassName modalWrap层的样式。主要用于排列布局
 * @wrapStyles modalWrap层的样式。主要用于排列布局
 * @className modal的样式。用于覆盖modal的样式
 * @onMaskClick 点击蒙层回调
 * @onClose 弹窗即将关闭的回调
 * @afterClose Modal 完全关闭后的回调
 * @modalPassiveUnmountCallback 弹窗被动卸载后调用，主要指用户使用浏览器返回按钮关闭弹窗的情况
*/
import { Component, CSSProperties, Fragment, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { type as UtilType } from 'fe-yb-tools';
import store from 'store';
import { PageInteractionLogManager, PageInteractionLogManager2, InteractionTopic, AllInteractionTopicOptions } from 'ybcommon/ybutils/PageInteractionLogManager';
import './index.less';
import MaskDomUtil from 'src/common/utils/maskDomUtil';
import { isFunction, throttle } from 'lodash-es';
import { CloseIcon, CloseIconProps } from 'src/component/Modals/CloseIcon';

const { toolStore } = store;

/** 弹框关闭类型 */
export enum CLOSE_TYPE {
  /** 点击叉号关闭 */
  X = 1,
  /** 点击蒙层关闭 */
  MASK = 2,
  /** 系统返回关闭 */
  BACK = 3,
  /** 点击按钮关闭，例如点击主按钮时关闭弹窗，目前仅部分弹窗使用该枚举 */
  CLICK_BTN = 4,
  /** 拦截弹窗，目前仅部分弹窗使用该枚举 */
  DETAIN_MODAL_CLOSE = 5,
  /** 引导弹窗点击叉号触发的关闭 */
  GUIDEMODALX = 6,
}

/** 弹框关闭类型 */
export const CLOSE_TYPE_NAME: Map<CLOSE_TYPE, string> = new Map([
  /** 点击叉号关闭 */
  [CLOSE_TYPE.X, 'clickIconX'],
  /** 点击蒙层关闭 */
  [CLOSE_TYPE.MASK, 'clickMask'],
  /** 系统返回关闭 */
  [CLOSE_TYPE.BACK, 'backEvent'],
  /** 点击按钮关闭，例如点击主按钮时关闭弹窗，目前仅部分弹窗使用该枚举 */
  [CLOSE_TYPE.CLICK_BTN, 'clickBtn'],
  /** 拦截弹窗，目前仅部分弹窗使用该枚举 */
  [CLOSE_TYPE.DETAIN_MODAL_CLOSE, 'detainModalClose'],
]);

export interface YBModalProps {
  visible?: boolean;
  /**
   * @default false
   */
  forbidOpenAnimation?: boolean;
  /**
   * 属性为false时，animationType默认为'fade'。 属性为true时，animationType默认为'slide-up'可指定为'slide-down'
   * @default false
   */
  popup?: boolean;
  /** 目前暂时用 string */
  // animationType?: 'fade' | 'slide-up' | 'slide-down';
  animationType?: string;
  /**
   * 让modal背景为透明，默认为白色。
   *
   * tip:
   * - 目前该样式存在一些副作用，具体请查看样式文件，使用时需确认和需求样式是否有冲突
   */
  transparent?: boolean;
  className?: string;
  wrapClassName?: string;
  wrapStyles?: CSSProperties;
  bgOpacity?: number;
  modalStyle?: CSSProperties;
  showAnimationStyle?: boolean;
  contentStyle?: CSSProperties;
  /**
   * mask 元素属性
   */
  maskProps?: Omit<HTMLAttributes<HTMLDivElement>, 'className' | 'style' | 'onClick' | 'onTouchMove' | 'onTouchEnd'> & { 'data-touch-play-audio'?: '0' };
  onOpened?: () => void;
  modalPassiveUnmountCallback?: () => void;
  onShowRendered?: () => void;
  /**
   * 弹窗即将关闭的回调
   * @param closeType 关闭类型。
   */
  onClose?: (closeType?: CLOSE_TYPE) => void;
  /**
   * 完全关闭后的回调
   * @param closeType 关闭类型。
   */
  afterClose?: (closeType?: CLOSE_TYPE) => void;
  /**
   * 点击蒙层回调。无默认值。
   */
  onMaskClick?: () => void;
  onMaskTouchEnd?: () => void;
  /**
   * 滑动蒙层回调。无默认值。
   */
  onMaskSlideEnd?: () => void;
  /**
   * @deprecated 历史上未使用的 props，请不要再使用
   */
  // eslint-disable-next-line react/no-unused-prop-types
  maskClosable?: boolean;
  interactionShowModalOptions?: AllInteractionTopicOptions[InteractionTopic.ShowModal];
  /**
   * 透传给 `<CloseIcon>` 组件的参数。
   */
  closeIconProps?: CloseIconProps;
  /**
   * 点击蒙层时是否关闭弹窗。当配置为 true，或者调用后返回 true 时，将关闭弹窗。
   * @default false
   */
  closeOnClickMask?: boolean | (() => boolean);
  /**
   * 滑动蒙层时是否关闭弹窗。当配置为 true，或者调用后返回 true 时，将关闭弹窗。
   * @default false
   */
  closeOnSlideMask?: boolean | (() => boolean);
  /**
   * 点击关闭按钮时是否关闭弹窗。当配置为 true，或者调用后返回 true 时，将关闭弹窗。
   * @default false
   */
  closeOnClickIcon?: boolean | (() => boolean);
}

export interface YBModalState {
  renderVisible: boolean;
  modalVisible: boolean;
}

export default class YBModal extends Component<YBModalProps, YBModalState> {
  static defaultProps = {
    onOpened: () => { }, // 弹窗打开后调用
    modalPassiveUnmountCallback: () => { }, // 弹窗被动卸载后调用，主要指用户使用浏览器返回按钮关闭弹窗的情况
    onShowRendered: () => { }, // 当展示弹窗的时候渲染元素后（动效之前）执行，此时可以获取dom等操作
    forbidOpenAnimation: false, // 打开弹窗时不执行动画
    // 弹窗交互options
    interactionShowModalOptions: {},
  };

  id = '';

  modalContentId = '';

  /** 用户手指是否移动过 */
  hasUserTouchMoved = false;

  /**
   * 表示 interaction 的唯一 id，被 {@link PageInteractionLogManager2} 使用
   */
  interactionId: number | null = null;

  constructor(props: YBModalProps) {
    super(props);
    this.id = `ybm${Math.random().toString(36).slice(-6)}`;
    this.modalContentId = `ybm${Math.random().toString(36).slice(-6)}`;
    this.state = {
      renderVisible: false,
      modalVisible: !!props.forbidOpenAnimation,
    };
  }

  componentDidMount() {
    /** 正在展示弹窗，记录交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.ShowModal);
    this.showModal();
  }

  componentWillUnmount() {
    toolStore.removeYbModalId(this.id);
    // 使用浏览器返回按钮，导致弹窗被动卸载时处理的逻辑
    const { modalPassiveUnmountCallback, visible } = this.props;
    if (modalPassiveUnmountCallback && visible) {
      modalPassiveUnmountCallback();
    }

    PageInteractionLogManager2.endInteraction(this.interactionId);
  }

  componentDidUpdate(preProps) {
    const { visible } = this.props;
    if (visible !== preProps.visible) {
      if (visible) {
        this.showModal();
      } else {
        this.closeModal();
      }
    }
  }

  showModal = () => {
    const { visible, onOpened, onShowRendered, forbidOpenAnimation, interactionShowModalOptions } = this.props;
    if (!visible) return;
    this.interactionId = PageInteractionLogManager2.startInteraction(InteractionTopic.ShowModal, interactionShowModalOptions);

    // showModal调用后如果用户机器性能较差会导致renderVisible的更新较慢，期间用户可能会点击页面上的其他按钮导致双弹窗。
    MaskDomUtil.addMaskDom(5);

    toolStore.addYbModalId(this.id);
    // 通过延时方法控制显示顺序 先显示外层结构 再显示modal
    setTimeout(() => {
      this.setState({
        renderVisible: visible,
      }, () => {
        if (onShowRendered) onShowRendered();
        setTimeout(() => {
          this.setState({
            modalVisible: visible,
          });
          if (forbidOpenAnimation) {
            // 弹窗打开，释放页面
            MaskDomUtil.removeMaskDom();
            onOpened();
          } else {
            setTimeout(() => {
              // 弹窗打开，释放页面
              MaskDomUtil.removeMaskDom();
              onOpened();
            }, 500);
          }
        }, 20);
      });
    });
  };

  closeModal = (closeType?: CLOSE_TYPE) => {
    const { onClose, afterClose } = this.props;
    if (onClose && typeof onClose === 'function') onClose(closeType);
    this.setState({
      modalVisible: false,
    }, () => {
      toolStore.removeYbModalId(this.id);
      // 为了保证关闭时依然显示动画效果，给了与过渡动画相同事件的等待时间，再这之后再关闭
      setTimeout(() => {
        this.setState({
          renderVisible: false,
        }, () => {
          // 当页面上所有通过编程式打开的弹窗关闭时再销毁弹窗
          if (!toolStore.modalVisible) {
            toolStore.modalType = -1;
            toolStore.modalProps = null;
          }
          /** 关闭弹窗，执行记录交互的回调 */
          PageInteractionLogManager.endCurInteraction();
          PageInteractionLogManager2.endInteraction(this.interactionId);
          if (afterClose && typeof afterClose === 'function') afterClose(closeType);
        });
      }, 450);
    });
  };

  private throttledCloseModal = throttle(this.closeModal, 50);

  _onClickMask = (e) => {
    e.stopPropagation();
    const { onMaskClick, closeOnClickMask = false } = this.props;
    onMaskClick?.();

    if (isFunction(closeOnClickMask) ? closeOnClickMask() : closeOnClickMask) {
      this.closeModal(CLOSE_TYPE.MASK);
    }
  };

  _onMaskTouchEnd = (e) => {
    // 判断当前点击元素是否为蒙层，点击子元素时不触发蒙层的onTouchEnd事件
    const targetClass = e?.target?.className || '';
    const isTargetMask = targetClass?.includes && UtilType.isFunction(targetClass.includes) && targetClass.includes('yb-modal-mask');
    if (!isTargetMask) return;
    const { closeOnSlideMask = false, onMaskSlideEnd, onMaskTouchEnd } = this.props;
    if (this.hasUserTouchMoved) {
      // 用户滑动结束
      onMaskSlideEnd?.();
      if (isFunction(closeOnSlideMask) ? closeOnSlideMask() : closeOnSlideMask) {
        this.throttledCloseModal(CLOSE_TYPE.MASK);
      }
      this.hasUserTouchMoved = false;
    }
    // 这个会阻止所有弹窗内滑动事件的传播，所以只需要在滑动的元素是蒙层时阻止传播即可
    e.stopPropagation();
    onMaskTouchEnd?.();
  };

  _onClickModal = (e) => {
    e.stopPropagation();
  };

  private _handleClickCloseIcon = (e: React.MouseEvent<HTMLImageElement>) => {
    const { closeIconProps, closeOnClickIcon = false } = this.props;
    closeIconProps.onClick?.(e);
    if (isFunction(closeOnClickIcon) ? closeOnClickIcon() : closeOnClickIcon) {
      this.closeModal(CLOSE_TYPE.X);
    }
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  _renderChild = (child, index?) => <Fragment key={`${index + 1}`}>{child}</Fragment>;

  render() {
    const {
      children,
      popup = false,
      animationType = 'fade',
      transparent = false,
      className = '',
      wrapClassName = '',
      wrapStyles = {},
      contentStyle = {},
      bgOpacity = 0.6,
      modalStyle = {},
      showAnimationStyle = true,
      closeIconProps,
      maskProps = {},
    } = this.props;
    const {
      renderVisible,
      modalVisible,
    } = this.state;
    // modal开始状态
    let animationStyle = 'yb-modal-fade';
    // modal结束状态
    let transClassName = 'yb-modal-showfade';
    let popupStyle = null;
    if (popup) {
      popupStyle = 'yb-modal-popup';
      if (animationType === 'slide-down') {
        animationStyle = 'yb-modal-popup-slidedown';
        transClassName = 'yb-modal-slidedown';
      } else {
        animationStyle = 'yb-modal-popup-slideup';
        transClassName = 'yb-modal-slideup';
      }
    } else {
      popupStyle = null;
      animationStyle = 'yb-modal-fade';
      transClassName = 'yb-modal-showfade';
    }
    if (!showAnimationStyle) animationStyle = '';
    const contentView = (
      <div
        id={this.id}
        className="yb-modal-main"
        style={{ display: renderVisible ? 'block' : 'none', ...modalStyle }}
      >
        <div
          {...maskProps}
          className={classnames('yb-modal-wrap',
            wrapClassName || '',
            'yb-modal-mask',
            modalVisible ? 'yb-modal-masktrans' : null)}
          style={{ background: `rgba(0, 0, 0, ${bgOpacity})`, ...wrapStyles }}
          onClick={this._onClickMask}
          onTouchMove={() => {
            this.hasUserTouchMoved = true;
          }}
          onTouchEnd={this._onMaskTouchEnd}
        >
          <div
            className={
              classnames(
                'yb-modal',
                transparent ? 'yb-modal-transparent' : null,
                popupStyle,
                className || '',
                // renderClassName,
                animationStyle,
                modalVisible ? transClassName : null,
              )
            }
            style={{ ...contentStyle }}
            onClick={this._onClickModal}
          >
            <div className="yb-modal-content" id={this.modalContentId}>
              <CloseIcon {...closeIconProps} onClick={this._handleClickCloseIcon} />
              <div className="yb-modal-body">
                {
                  Array.isArray(children)
                    ? children.map((child, index) => this._renderChild(child, index))
                    : children && this._renderChild(children)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return ReactDOM.createPortal(contentView, document.body);
  }
}
