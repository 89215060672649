/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2023-09-19 15:05:13
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2023-09-19 18:02:49
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/my/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    path: '/my',
    component: () => import('./index.js'),
    routes: [
      {
        path: '/my/feedBack',
        component: () => import('./Index/feedBack/index.js'),
      },
      {
        path: '/my/familyProtection',
        title: '家庭保障',
        component: () => import('./Index/familyProtection'),
      },
    ],
  },
];
