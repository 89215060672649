/*
 * @Author: 朱晓真
 * @Date: 2022-10-27 16:18:51
 * @LastEditors: liulongjie liulongjie@yuanbaobaoxian.com
 * @LastEditTime: 2025-02-14 20:03:04
 * @Description: 请填写简介
 */
import React, { Component } from 'react';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import classNames from 'classnames';
import styles from './index.less';

export default class RelationTagCheck extends Component {
  static defaultProps = {
    tags: [],
    value: 1, // 当前选中关系1、2、4、3
    onTagChange: () => { }, // 回调事件（切换关系）
    showProLabel: false, // 是否展示已保障标签
    proLabelSrc: require('./img/proLabel.png'), // 已保障图片
    proLabelImgClass: '', // 已保障
    actItemClass: '', // 选中class
    disactItemClass: '', // 未选中class
    gap: 7, // 左边距
    doTips: () => { }, // 回调事件（alert提示）
    showSelectIcon: false, // 是否显示的选中图标
    selectIconImg: require('./img/clickIcon2.png'), // 选中icon
    selectIconClass: '', // 选中Icon类名样式
    showApplicantLabel: false, // 链路2 表单版本2、3 选中本人时 展示投/被报人label
  };

  _renderItem = (val, index) => {
    const {
      value,
      onTagChange,
      showProLabel,
      proLabelSrc,
      proLabelImgClass,
      actItemClass,
      disactItemClass,
      gap,
      doTips,
      showSelectIcon,
      selectIconImg,
      selectIconClass,
      showApplicantLabel,
    } = this.props;
    const _id = val.id;
    const itemClassName = _id === value ? actItemClass : disactItemClass;
    return (
      <div
        key={`${index + 1}`}
        className={classNames('relItem', itemClassName, styles.itemContainer)}
        style={{
          marginLeft: index === 0 || index === 4 ? 0 : `${pxTOrem(gap)}rem`,
        }}
        data-observe-id="1"
        onClick={() => {
          if ((_id !== value)) {
            if ((_id === 1 && showProLabel)) {
              if (doTips) {
                doTips(_id);
              }
            } else {
              onTagChange(_id);
            }
          }
        }}
      >
        {val.borderImg && <img src={val.borderImg} className={classNames(value === _id && styles.actBorderImgStyle, styles.borderImgStyle)} alt=" " />}
        <p className={styles.text}><span>{val.text}</span></p>
        {(_id === 1 && showProLabel) ? <img src={proLabelSrc} className={classNames(proLabelImgClass, styles.proLabelSrcStyle)} alt="" /> : null}
        {showApplicantLabel && _id === 1 && <img src={require('./img/applicantLabel.png')} className={styles.applicantLabel} alt="" />}
        {_id === value && showSelectIcon ? <img src={selectIconImg} alt="" className={classNames(styles.selectIconStyle, selectIconClass)} /> : null}
      </div>
    );
  };

  // 额外的占位选项
  _renderItemExtra = (val, index) => {
    const { gap } = this.props;
    return (
      <div
        style={{
          marginLeft: `${pxTOrem(gap)}rem`,
        }}
        key={`extra_${index + 1}`}
        className={styles.itemContainer2}
      />
    );
  };

  render() {
    const { tags, conClassName, extraTag = null } = this.props;
    return (
      <div className={conClassName ? `${styles.tagContainer} ${conClassName}` : styles.tagContainer}>
        {tags.map(this._renderItem)}
        {extraTag && extraTag.map(this._renderItemExtra)}
      </div>
    );
  }
}
