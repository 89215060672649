/**
 * 页面交互记录manager，有以下页面交互需要记录
 * 点击协议 普通协议弹窗
 * 勾选协议
 * 滑动
 * 操作返回
 * 有无医保弹窗
 * 保障计划-查看详情弹窗
 * 续保协议弹窗
 * 键盘聚焦
 */

import { startTouchListener } from './touchUtil';
import { InteractionTopic, InteractionCBType } from './type';

class Manager {
  /** 交互结束后回调数组 */
  private onInteractionEnds: Array<InteractionCBType> = [];

  /** 交互开始回调数组 */
  private onInteractionStarts: Array<InteractionCBType> = [];

  /** 当前正在执行的交互 */
  private curInteraction: InteractionTopic | null = null;

  // constructor() {
  // let isTouchStart = false;
  // document.addEventListener('touchstart', () => { if (!this.curInteraction) this.startInteraction(InteractionTopic.TouchMove); isTouchStart = true; });
  // document.addEventListener('touchend', () => { if (isTouchStart) this.endCurInteraction(); });
  // }

  /** 添加交互结束监听回调 */
  addInteractionEndCB(interactionEndCB: InteractionCBType, canAddListenTouch = false) {
    // this.onInteractionEnd = options.onInteractionEnd;
    this.onInteractionEnds.push(interactionEndCB);
    // this.noNeedLogList = noNeedLogList;
    if (canAddListenTouch) {
      // document.addEventListener('touchstart', () => this.startInteraction(InteractionTopic.TouchMove), { once: true });
      // document.addEventListener('touchend', () => this.endCurInteraction(), { once: true });
      startTouchListener(() => {
        this.startInteraction(InteractionTopic.TouchMove);
        this.endCurInteraction();
      });
    }
  }

  /**
   * 删除 end callback 列表中的回调函数
   * @param cb 需要删除的回调函数
   */
  removeInteractionEndCB(cb: InteractionCBType) {
    const pos = this.onInteractionEnds.indexOf(cb);
    if (pos >= 0) {
      this.onInteractionEnds.splice(pos, 1);
    }
  }

  /** 在记录之前进行init相关初始化操作 */
  addInteractionStartCB(interactionStartCB: InteractionCBType, canAddListenTouch = false) {
    // this.onInteractionEnd = options.onInteractionEnd;
    this.onInteractionStarts.push(interactionStartCB);
    // this.noNeedLogList = noNeedLogList;
    if (canAddListenTouch) {
      // document.addEventListener('touchstart', () => this.startInteraction(InteractionTopic.TouchMove), { once: true });
      // document.addEventListener('touchend', () => this.endCurInteraction(), { once: true });
      startTouchListener(() => {
        this.startInteraction(InteractionTopic.TouchMove);
        this.endCurInteraction();
      });
    }
  }

  /**
   * 删除 start callback 列表中的回调函数
   * @param cb 需要删除的回调函数
   */
  removeInteractionStartCB(cb: InteractionCBType) {
    const pos = this.onInteractionStarts.indexOf(cb);
    if (pos >= 0) {
      this.onInteractionStarts.splice(pos, 1);
    }
  }

  /**
   * 开始交互回调
   * 在各个交互记录的开始节点进行调用
   * 目前YBModal的使用已经囊括，ybModal的open调用、taskModal的调用 得手动添加
   */
  startInteraction = (interaction: InteractionTopic) => {
    this.curInteraction = interaction;

    if (this.onInteractionStarts.length) {
      const newInteractionStarts = [];
      this.onInteractionStarts.forEach((onInteractionStart) => {
        const isContinue = onInteractionStart(this.curInteraction);
        if (isContinue) {
          newInteractionStarts.push(onInteractionStart);
        }
      });
      this.onInteractionStarts = newInteractionStarts;
    }
  };

  /** 当前交互结束 */
  endCurInteraction = () => {
    if (!this.curIsHasInteraction()) return;

    if (this.onInteractionEnds.length) {
      const newInteractionEnds = [];
      this.onInteractionEnds.forEach((onInteractionEnd) => {
        const isContinue = onInteractionEnd(this.curInteraction);
        if (isContinue) {
          newInteractionEnds.push(onInteractionEnd);
        }
      });
      this.onInteractionEnds = newInteractionEnds;
    }

    this.curInteraction = null;
    // this.noNeedLogList = [];
  };

  /** 当前是否有交互正在进行 */
  curIsHasInteraction = (): boolean => !!this.curInteraction;

  /** 获取交互结束后回调 */
  // getOnInteractionEnd = () => this.onInteractionEnd;

  /** 获取交互等待中的promise */
  // getInteractionPromise = () => this.interactionPromise;
}

export { InteractionTopic } from './type';
export type { InteractionCBType, AllInteractionTopicOptions } from './type';
export { PageInteractionLogManager2 } from './modal';
export const PageInteractionLogManager = new Manager();
