/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 17:50:44
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2025-02-21 10:15:24
 * @Description: 请填写简介
 */
import { AxiosInstance } from 'axios';
import { getEnvConfigrationByDomain } from '../../envConfig/index';
import { CommonAbtestParams } from '../../types';
import { baseFetch, CommonHeader } from '../base';
import { AbTestCodeParam, AbtestParamListItem, ShuntAllAbTestParam } from './types';

export function composeAbtestParams(
  ybPosition: string | undefined,
  abTestParamsList: AbTestCodeParam[],
  commonAbtestData: CommonAbtestParams,
): ShuntAllAbTestParam {
  const shuntAppId = getEnvConfigrationByDomain().SHUNT_APP_ID;
  const paramList: AbtestParamListItem[] = abTestParamsList.map((item: AbTestCodeParam): AbtestParamListItem => {
    const { configCode, extraLimit } = item;
    return {
      configCode,
      params: {
        ...commonAbtestData,
        ...extraLimit,
      },
    };
  });
  return {
    shuntAppId,
    ybPosition,
    paramList,
  };
}

/**
 * 拼接分流降级到cdn时，json文件需要的参数
 * 会过滤掉每个分流实验中的 'userId', 'uuid', 'channel', 'ip', 'orderId', 'userIdencrypt' 这几个参数
 * @param abTestConfigParams
 * @returns string
 */
export function makeCdnParams(abTestConfigParams: AbtestParamListItem[]): string {
  const filterKeys = ['userId', 'uuid', 'channel', 'ip', 'orderId', 'userIdencrypt', 'openId', 'unionId', 'mediaUuid', 'auth-token', 'aaTestTag', 'ip2Region', 'ipRegion', 'data', 'requestOS', 'position', 'lastVisitTime', 'exchangeFlag', 'orderIdEncrypt', 'amount', 'configId', 'tradeNo'];
  const urlParams: string[] = [];
  abTestConfigParams.forEach((item) => {
    urlParams.push(`configKey=${item.configCode}`);
    const testConfigParams = item.params;
    Object.keys(testConfigParams).sort().forEach((key) => {
      if (item && testConfigParams[key] !== null && testConfigParams[key] !== undefined) {
        if (filterKeys.indexOf(key) === -1) {
          const value = testConfigParams[key];
          urlParams.push(`${key}=${value}`);
        }
      }
    });
  });
  return urlParams.join('&');
}

function handleShuntAllAbtestData(resultData: Record<string, any>): Record<string, any> {
  const templates = resultData.templates;
  for (const temKey in templates) {
    if (Object.prototype.hasOwnProperty.call(templates, temKey)) {
      // 解析templates里配置参数的template 拼回原对象
      let values = {};
      if (templates[temKey].template) {
        try {
          values = JSON.parse(templates[temKey].template);
        } catch (err) {
          // TODO 记得上报异常
          values = {};
        }
      } else {
        values = {};
      }
      const bucketNo = templates[temKey].bucketNo;
      templates[temKey] = { bucketNo, ...values, requestId: resultData.requestId };
    }
  }
  return templates;
}

export function shuntFetch(commonHeader: CommonHeader): AxiosInstance {
  return baseFetch({
    baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL,
    withCredentials: true,
  }, commonHeader, (res: Record<string, any>) => {
    if (res) {
      const handleData = handleShuntAllAbtestData(res);
      return handleData;
    }
    return res;
  });
}

/**
 * 创建无需携带凭据的分流请求Axios实例
 *
 * 该函数基于基础请求配置创建Axios实例，特别处理AB测试数据分流逻辑。适用于不需要携带身份凭证的跨域请求场景。
 *
 * @param commonHeader - 通用请求头配置对象，包含应用基础头信息
 * @returns 配置完成的Axios实例，该实例已集成响应数据分流处理逻辑
 */
export function shuntFetchWithoutCredentials(commonHeader: CommonHeader): AxiosInstance {
  // 基础请求配置：使用域名环境配置中的网关地址，显式关闭凭据携带（原withCredentials配置被注释）
  return baseFetch({
    baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL,
    // withCredentials: true,
  },
  commonHeader,
  // 响应数据处理器：对AB测试结果数据进行统一分流处理
  (res: Record<string, any>) => {
    if (res) {
      // 执行AB测试数据分流处理流程
      const handleData = handleShuntAllAbtestData(res);
      return handleData;
    }
    return res;
  });
}

/**
//  * 创建处理了默认配置和AB测试数据分流的Axios实例
//  *
//  * @param commonHeader - 公共请求头配置，包含需要在所有请求中携带的通用头部信息
//  * @returns {AxiosInstance} 配置完成的Axios实例，具备以下特性：
//  * 1. 基础URL通过域名对应的环境配置获取
//  * 2. 响应数据会经过AB测试数据分流处理
//  * 3. 携带公共请求头参数
//  */
// export function shuntDefaultFetch(commonHeader: CommonHeader): AxiosInstance {
//   // 创建基础axios实例，配置网关地址（根据当前域名获取对应环境配置）
//   return baseFetch({
//     baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL,
//     // withCredentials: true,
//   },
//   commonHeader,
//   (res: Record<string, any>) => {
//     // AB测试数据分流处理逻辑：当响应数据存在时，执行AB测试数据分流算法
//     if (res) {
//       const handleData = handleShuntAllAbtestData(res);
//       return handleData;
//     }
//     return res;
//   });
// }
