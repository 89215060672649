// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".closeIcon_T\\+Tcj9Pl {\n  position: absolute;\n}\n.closeIcon_T\\+Tcj9Pl.left0_1d9ExJMa {\n  left: 0;\n}\n.closeIcon_T\\+Tcj9Pl.left7_5_xS7zhxxu {\n  left: 0.2rem;\n}\n.closeIcon_T\\+Tcj9Pl.left20_dWPFDjiu {\n  left: 0.53333rem;\n}\n.closeIcon_T\\+Tcj9Pl.right0_gqLxV2VX {\n  right: 0;\n}\n.closeIcon_T\\+Tcj9Pl.right7_5_4c7P8QP2 {\n  right: 0.2rem;\n}\n.closeIcon_T\\+Tcj9Pl.right15_Cup6rHil {\n  right: 0.4rem;\n}\n.closeIcon_T\\+Tcj9Pl.right20_wD6MnYXr {\n  right: 0.53333rem;\n}\n.closeIcon_T\\+Tcj9Pl.inside_osCG-v28 {\n  top: 0.4rem;\n}\n.closeIcon_T\\+Tcj9Pl.outside_9CTmGBeO {\n  margin-bottom: 0.53333rem;\n  bottom: 100%;\n}\n.closeIcon_T\\+Tcj9Pl.normalIcon_Mk8KZ1rX {\n  width: 0.56rem;\n  height: 0.56rem;\n}\n.closeIcon_T\\+Tcj9Pl.xIcon_t0286RdQ {\n  width: 0.53333rem;\n  height: 0.53333rem;\n}\n.closeIcon_T\\+Tcj9Pl.circleIcon_ONNIIs\\+b {\n  width: 0.72rem;\n  height: 0.72rem;\n}\n.closeIcon_T\\+Tcj9Pl.circle2Icon_k5gvPam0 {\n  width: 0.8rem;\n  height: 0.8rem;\n}\n.closeIcon_T\\+Tcj9Pl.circleFillIcon_X75ahrQT {\n  width: 0.8rem;\n  height: 0.8rem;\n}\n.closeIcon_T\\+Tcj9Pl.circleFill2Icon_a-hfiZfQ {\n  width: 0.66667rem;\n  height: 0.66667rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeIcon": "closeIcon_T+Tcj9Pl",
	"left0": "left0_1d9ExJMa",
	"left7_5": "left7_5_xS7zhxxu",
	"left20": "left20_dWPFDjiu",
	"right0": "right0_gqLxV2VX",
	"right7_5": "right7_5_4c7P8QP2",
	"right15": "right15_Cup6rHil",
	"right20": "right20_wD6MnYXr",
	"inside": "inside_osCG-v28",
	"outside": "outside_9CTmGBeO",
	"normalIcon": "normalIcon_Mk8KZ1rX",
	"xIcon": "xIcon_t0286RdQ",
	"circleIcon": "circleIcon_ONNIIs+b",
	"circle2Icon": "circle2Icon_k5gvPam0",
	"circleFillIcon": "circleFillIcon_X75ahrQT",
	"circleFill2Icon": "circleFill2Icon_a-hfiZfQ"
};
export default ___CSS_LOADER_EXPORT___;
