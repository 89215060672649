/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 17:50:44
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2025-02-25 18:51:40
 * @Description: 请填写简介
 */
import { AxiosResponse } from 'axios';
import md5 from 'md5';
import { trackEvent } from '../../../ybcommon/ybutils/statistic';
import { getEnvConfigrationByDomain } from '../../envConfig/index';
import { CommonApiParams, CommonAbtestParams } from '../../types';
import { CommonHeader, DataResponse, CustomAxiosError } from '../base';
import { composeAbtestParams, makeCdnParams, shuntFetch, shuntFetchWithoutCredentials } from './shuntFetch';
import { AbTestCodeParam } from './types';
import { getLogger } from '../../webLogger';

/**
 * 生成分流请求
 * 泛型参数必须继承 {BaseShuntAllResult}，并在具有templates属性
 * @param commonHeader {CommonHeader} 公共header
 * @returns {Promise<AxiosResponse<DataResponse<T>, unknown>}  返回结果
 */
export async function getShuntAbtestAll<T>(
  ybPosition: string | undefined,
  abTestParamsList: AbTestCodeParam[],
  commonAbTestParams: CommonAbtestParams,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
// eslint-disable-next-line max-len
): Promise<AxiosResponse<DataResponse<T>, unknown>> {
  const shuntFetchInstance = shuntFetch(commonHeader);
  const params = { ...(commonApiParams || {}), ...composeAbtestParams(ybPosition, abTestParamsList, commonAbTestParams) };
  try {
    const result = await shuntFetchInstance.post('/api/growth/shunt/v1/determine/batchGetPage', params);
    if (result?.data?.code !== 200) {
      const { code, msg } = result?.data || {};
      throw new CustomAxiosError(code, msg, new Error(msg));
    } else {
      return result;
    }
  } catch (err) {
    // 异常 走OSS兜底
    trackEvent?.('h5_fe_shunt_request_md5', { err });
    getLogger().info('shunterr1', { err: JSON.stringify(err) });
    try {
      const shuntFetchDefaultInstance = shuntFetchWithoutCredentials(commonHeader);
      const result = await shuntFetchDefaultInstance.post('api/growth/shunt/default/determine/batchGetPage', params);
      if (result?.data?.code !== 200) {
        const { code, msg } = result?.data || {};
        throw new CustomAxiosError(code, msg, new Error(msg));
      } else {
        return result;
      }
    } catch (e) {
      getLogger().info('shunterr2', { err: JSON.stringify(e) });
      // 异常 走CDN兜底
      trackEvent?.('h5_fe_shunt_oss2Fail', { message: '缓存json2失败', err: e });
      const t = new Date().getTime();
      const urlParamsStr = makeCdnParams(params.paramList);
      const name = md5(urlParamsStr);
      getLogger().info('shuntcdn', { md5PreStr: urlParamsStr, md5AfterStr: name });
      const md5Url = `${getEnvConfigrationByDomain().SHUNT_CDN_URL}${name}.json?v=${t}`;
      try {
        const result = await shuntFetchWithoutCredentials(commonHeader).get(md5Url);
        return result;
      } catch (error) {
        trackEvent?.('h5_fe_shunt_ossFail', { message: '缓存json失败', data: urlParamsStr, err: error, md5Url });
        throw error;
      }
    }
  }
}
