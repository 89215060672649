/* eslint-disable react/require-default-props */
import { CSSProperties, FC, useMemo } from 'react';
import classNames from 'classnames';
import closeIcon from 'src/common/img/close.png';
import close2Icon from 'src/common/img/close2.png';
import closeCircleIcon from 'src/common/img/closeCircleIcon.png';
import closeCircle2Icon from 'src/common/img/closeCircle2Icon.png';
import closeCircleFillIcon from 'src/common/img/closeCircleFillIcon.png';
import closeCircleFill2Icon from 'src/common/img/closeCircleFill2Icon.png';
import { getImgPath } from 'ybcommon/ybutils/common/common';
import styles from './index.module.less';

export const enum Icon {
  /**
   * 一个 × 图标。对应图片
   *
   * ![src/common/img/close.png](../../../common/img/close.png)
   *
   * 使用方
   * - 可回溯12 组件中默认使用的图标（可回溯12使用的是较为通用的 Modal 组件）
   */
  Normal = 'normal',
  /**
   * 一个 × 图标，和 {@link Icon.Normal} 样式略有不同。对应图片
   *
   * ![src/common/img/close2.png](../../../common/img/close2.png)
   *
   * 使用方
   * - 可回溯11，在弹窗内部的关闭按钮
   */
  X = 'x',
  /**
   * 一个 × 图标 + 外层的圆圈。对应图片
   *
   * ![src/common/img/closeCircleIcon.png](../../../common/img/closeCircleIcon.png)
   *
   * 使用方
   * - 升级 108/11（版本已下线）
   */
  Circle = 'circle',
  /**
   * 一个 × 图标 + 外层的圆圈。对应图片
   *
   * ![src/common/img/closeCircle2Icon.png](../../../common/img/closeCircle2Icon.png)
   *
   * 使用方
   * - [结果页未投保引导弹窗](https://yuanbaoshuke.feishu.cn/docx/WBpQdt4THoj3mUx8oMncz27knOk)
   */
  Circle2 = 'circle2',
  /**
   * 圆形内镂空 x 图标。对应图片
   *
   * ![src/common/img/closeCircleFillIcon.png](../../..//common/img/closeCircleFillIcon.png)
   *
   * 使用方
   * - modalCloseIconAbtest 所使用的图标
   */
  CircleFill = 'circleFill',
  /**
   * 圆形内镂空 x 图标，和 {@link Icon.CircleFill} 样式略有不同。对应图片
   *
   * ![src/common/img/closeCircleFill2Icon.png](../../../common/img/closeCircleFill2Icon.png)
   *
   * 使用方
   * - 升级 4/7/8 弹窗
   * - 升级 103/105 弹窗
   */
  CircleFill2 = 'circleFill2',
}

/**
 * 预设 icon 图片链接
 */
const ICON: Record<Icon, string> = {
  /**
   * 一个 × 图标
   *
   * - 可回溯12 组件中默认使用的图标（可回溯12使用的是较为通用的 Modal 组件）
   */
  [Icon.Normal]: closeIcon,
  /**
   * 一个 × 图标，和 {@link ICON.normal} 样式略有不同
   *
   * - 可回溯11，在弹窗内部的关闭按钮
   */
  [Icon.X]: close2Icon,
  /**
   * 一个 × 图标 + 外层的圆圈
   *
   * 使用方
   * - 升级 108/11（版本已下线）
   */
  [Icon.Circle]: closeCircleIcon,
  /**
   * 一个 × 图标 + 外层的圆圈
   *
   * 使用方
   * - [结果页未投保引导弹窗](https://yuanbaoshuke.feishu.cn/docx/WBpQdt4THoj3mUx8oMncz27knOk)
   */
  [Icon.Circle2]: closeCircle2Icon,
  /**
   * 圆形内镂空 x 图标
   *
   * - modalCloseIconAbtest 所使用的图标
   */
  [Icon.CircleFill]: closeCircleFillIcon,
  /**
   * 圆形内镂空 x 图标，和 {@link ICON.circleFill} 样式略有不同
   *
   * 使用方
   * - 升级 4/7/8 弹窗
   * - 升级 103/105 弹窗
   */
  [Icon.CircleFill2]: closeCircleFill2Icon,
} as const;

export enum Align {
  /** 距离内容左 edge 20像素，一般配合 position = 'outside' 使用 */
  Left20 = 'left20',
  /** 距离内容左 edge 7.5像素 */
  Left7_5 = 'left7_5',
  /** 距离内容左 edge 0像素 */
  Left0 = 'left0',
  /** 距离内容右 edge 20像素，一般配合 position = 'outside' 使用 */
  Right20 = 'right20',
  /** 距离内容右 edge 15像素 */
  Right15 = 'right15',
  /** 距离内容右 edge 7.5像素 */
  Right7_5 = 'right7_5',
  /** 距离内容右 edge 0像素 */
  Right0 = 'right0',
}

export enum Position {
  /**
   * 距离弹窗顶部 edge 15像素，在弹窗内部
   *
   * - 目前仅在可回溯11全屏模式下使用
   *
   * @deprecated 暂时不建议使用。可能需要交互定义内部关闭按钮的统一规范。
   */
  Inside = 'inside',
  /** 距离弹窗顶部 edge 20像素，在弹窗外部 */
  Outside = 'outside',
}

/**
 * `<CloseIcon>` 组件参数
 */
export interface CloseIconProps {
  className?: string;
  style?: CSSProperties;
  /**
   * 使用预设 icon。无默认值。
   */
  icon?: Icon;
  /**
   * icon 图片路径，优先级更高，会覆盖 {@link CloseIconProps.icon} 配置。无默认值。
   */
  src?: string;
  /**
   * icon 位置，控制在弹窗左侧还是右侧。参考 {@link Align}
   * @default Align.Right20
   */
  align?: Align;
  /**
   * icon 位置，控制在弹窗内部还是外部。参考 {@link Position}
   * @default Position.Outside
   */
  position?: Position;
  /**
   * 点击回调。无默认值。
   */
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  /**
   * `<AudioBGManager>` 中使用，传入 '0' 时用于避免点击关闭按钮时播放音频。
   * @todo 类型由 `<AudioBGManager>` 定义。
   */
  'data-touch-play-audio'?: '0';
}

/**
 * 关闭按钮，当没有可展示的 {@link CloseIconProps.icon} 或 {@link CloseIconProps.src} 时，将不渲染任何元素。
 */
export const CloseIcon: FC<CloseIconProps> = (props) => {
  const { className: propClassName, icon, src, align = Align.Right20, position = Position.Outside, onClick, ...rest } = props;

  const className = useMemo(() => classNames(
    styles.closeIcon,
    icon ? styles[`${icon}Icon`] : undefined,
    styles[`${align}`],
    styles[`${position}`],
    propClassName,
  ), [align, position, icon, propClassName]);

  const result = useMemo(() => {
    let raw: string | null = null;
    if (src) {
      raw = src;
    } else if (icon && ICON[icon]) {
      raw = ICON[icon];
    }

    return getImgPath(raw);
  }, [src, icon]);

  if (!result) {
    return null;
  }

  return <img className={className} src={result} alt="closeIcon" onClick={onClick} {...rest} />;
};
